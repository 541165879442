/* App.module.css */

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .inputContainer {
    display: flex;
    margin-bottom: 20px;
  }
  
  input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .shortUrl {
    margin-bottom: 20px;
  }
  
  .analysisResult {
    margin-top: 20px;
  }
  
  .analysisResult p {
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 5px;
  }


  .timeandDate {
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    padding: 0;
  }
  
  .logItem {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  .inputBox{
    outline: none;
  }
  